import { datadogLogs } from "@datadog/browser-logs";

const ENV = window.DATADOG_ENV;
const SERVICE = window.DATADOG_SERVICE;

const DATADOG_CLIENT_TOKEN = "pub665229263c222674ca01648cad1610a6";

const DATADOG_SITE = "us5.datadoghq.com";

export function dataDogLogsInit() {
  datadogLogs.init({
    clientToken: DATADOG_CLIENT_TOKEN,
    site: DATADOG_SITE,
    forwardErrorsToLogs: true,
    forwardConsoleLogs: "all",
    sessionSampleRate: 100,
    env: ENV,
    service: SERVICE,
  });
}
